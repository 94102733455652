var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: "card-" + _vm.type + " card-frame visible" }, [
    _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", { staticClass: "icon" }, [
            _c(
              "div",
              { staticClass: "lang" },
              _vm._l(_vm.langs, function (lang) {
                return _c(_vm.displayLangIcon(lang), {
                  key: lang,
                  tag: "component",
                  attrs: { size: "1.2x" },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("i", { class: _vm.displayIcon }),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.description))]),
      ]),
      _vm._v(" "),
      _vm.gif == "true"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "media",
                style: _vm.clBackgroundImage("video/upload"),
              },
              [
                _c("video", {
                  attrs: {
                    loop: "",
                    muted: "muted",
                    autoplay: "",
                    src:
                      "https://res.cloudinary.com/yanninthesky/video/upload/" +
                      _vm.img +
                      ".webm",
                  },
                  domProps: { muted: true },
                }),
              ]
            ),
          ])
        : _c("div", [
            _c("div", {
              staticClass: "media",
              style: _vm.clBackgroundImage("image/upload/yannklein.me"),
            }),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }