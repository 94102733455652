var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content main-content-visible" },
    [
      _c("SideBar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("Intro"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "portfolio" },
            _vm._l(_vm.projects, function (project) {
              return _c(
                "Card",
                _vm._b({ key: project.url }, "Card", project, false)
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }