var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", { staticClass: "sidebar sidebar-left" }, [
        _c("a", { staticClass: "icon icon-vr", attrs: { href: "#" } }, [
          _c("i", { staticClass: "fas fa-vr-cardboard" }),
          _vm._v(" "),
          _c("p", { staticClass: "icon-text" }, [_vm._v("VR/AR")]),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "icon icon-code", attrs: { href: "#" } }, [
          _c("i", { staticClass: "fas fa-laptop-code" }),
          _vm._v(" "),
          _c("p", { staticClass: "icon-text" }, [_vm._v("I code")]),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "icon icon-maker", attrs: { href: "#" } }, [
          _c("i", { staticClass: "fas fa-tools" }),
          _vm._v(" "),
          _c("p", { staticClass: "icon-text" }, [_vm._v("I make")]),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "icon icon-help", attrs: { href: "#" } }, [
          _c("i", { staticClass: "fas fa-hands-helping" }),
          _vm._v(" "),
          _c("p", { staticClass: "icon-text" }, [_vm._v("I care")]),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "icon icon-love", attrs: { href: "#" } }, [
          _c("i", { staticClass: "fas fa-heart" }),
          _vm._v(" "),
          _c("p", { staticClass: "icon-text" }, [_vm._v("I love")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar sidebar-right" }, [
        _c("a", { attrs: { href: "https://github.com/yannklein" } }, [
          _c("i", { staticClass: "fab fa-github" }),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "https://twitter.com/yannlucklein" } }, [
          _c("i", { staticClass: "fab fa-twitter" }),
        ]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://www.linkedin.com/in/yann-klein/" } },
          [_c("i", { staticClass: "fab fa-linkedin-in" })]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }